@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.radioButton {
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 16px 1.25rem;
  text-align: center;
  width: fit-content;
  cursor: pointer;

  p {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    margin-left: 9px;
  }
  .icon {
    margin-right: 10px;
  }
}
.checked {
  border: 1px solid #7557bb;
  color: #7557bb;
  // p {
  //   // font-weight: 500;
  // }
  svg {
    path {
      fill: #7557bb;
    }
  }
}
